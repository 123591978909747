import React, { Fragment } from 'react'
import Hero from '../components/UI/Hero'
import SEO from '../components/SEO'
import VehicleListing from '../components/UI/VehicleListing'
import { graphql } from 'gatsby'

export default class Fleet extends React.Component {
  _renderVehicleListing = () => {
    let { edges } = this.props.data.fleetVehicles
    return <VehicleListing vehicles={edges} />
  }
  render() {
    return (
      <Fragment>
        <SEO title={`Fleet`} />
        <Hero heroStyle="tertiary" title="SGL Motors Fleet" />
        {this._renderVehicleListing()}
      </Fragment>
    )
  }
}

export const fleetPageQuery = graphql`
  query {
    fleetVehicles: allAirtable(filter: { table: { eq: "Vehicles" } }) {
      edges {
        node {
          data {
            vehicleImages {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            active
            vehicleMake
            vehicleModel
            vehicleYear
            vehicleName
            vehicleType {
              data {
                name
              }
            }
          }
        }
      }
    }
  }
`
